.ar__navbar-menu_container,
.dark-theme .ar__navbar-menu_container {
  background: var(--color-background);
  color: var(--color-primary);
}
.ar__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;
}
.ar__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ar__navbar-links_logo {
  margin-right: 5rem;
}
.ar__navbar-links_logo img {
  max-width: 90px;
  width: 100%;
  filter: brightness(80%);
  transition: filter 0.3s;
  cursor: pointer;
}
.custom-logo-skeleton.MuiSkeleton-root {
  width: 90px;
  height: 90px;
}
.dark-theme .ar__navbar-links_logo img {
  filter: brightness(0) invert(1);
  transition: filter 0.3s;
}
.dark-theme .ar__navbar-links_logo img:hover {
  filter: brightness(1) invert(0);
}
.ar__navbar-links_logo img:hover {
  filter: brightness(50%);
}
.ar__navbar-links_container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}
.ar__navbar-links_container p a:hover {
  transition: color 0.2s;
  color: var(--color-accent);
}
.ar__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ar__navbar-links_container p,
.ar__navbar-sign p .ar__navbar-menu_container p {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
  letter-spacing: 0.1rem;
}
.ar__navbar-links_container p a,
.ar__navbar-menu_container p a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.ar__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}
.ar__navbar-menu svg {
  cursor: pointer;
}
.ar__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: left;
  border: 1px solid var(--color-accent);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 190px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.ar__navbar-menu_container p a {
  transition: color 0.3s;
}
.ar__navbar-menu_container p a:hover,
.nav__link__number {
  color: var(--color-accent);
}
.ar__navbar-menu_container p {
  margin: 1rem 0;
  font-weight: 500;
}
.ar__navbar-menu_container-links {
  margin-bottom: 1rem;
}
.ar__navbar-menu_container-links-sign {
  display: none;
}
.checkbox {
  opacity: 0;
  position: absolute;
  margin: 0 0 1rem;
  width: 85px;
  height: 30px;
  z-index: 1;
}
.label {
  width: 45px;
  height: 20px;
  background-color: #111;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin-left: 1rem;
  position: relative;
  transform: scale(1.5);
}
.toggle__icon.MuiSvgIcon-root {
  filter: brightness(0) invert(1);
  width: 12px;
  height: 12px;
}
.ball {
  width: 16px;
  height: 16px;
  background-color: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}
.checkbox:checked + .label .ball {
  transform: translateX(24px);
}
.nav__link__icon {
  width: 20px;
  height: 20px;
  margin-right: 0.8rem;
}
.dark-theme .nav__link__icon {
  filter: brightness(0) invert(1);
}
@media screen and (max-width: 1141px) {
  .ar__navbar-links_container p {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1088px) and (min-width: 1067px) {
  .ar__navbar-links_container p {
    font-size: 0.95rem;
  }
}
@media screen and (max-width: 1067px) {
  .ar__navbar-links_container {
    display: none;
  }
  .ar__navbar-menu {
    display: flex;
  }
  .ar__navbar-links_logo img {
    width: 80%;
  }
  .custom-logo-skeleton.MuiSkeleton-root {
    width: 80px;
    height: 80px;
  }
  .nav__link__icon {
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 700px) {
  .ar__navbar {
    padding: 2rem 4rem;
  }
  .ar__navbar-links_logo img {
    width: 70%;
  }
  .custom-logo-skeleton.MuiSkeleton-root {
    width: 70px;
    height: 70px;
  }
  .checkbox:checked + .label .ball {
    transform: translateX(24px);
  }
}
@media screen and (max-width: 550px) {
  .ar__navbar {
    padding: 2rem;
  }
  .ar__navbar-sign {
    display: none;
  }
  .ar__navbar-menu_container {
    top: 20px;
  }
}
@media screen and (max-width: 350px) {
  .ar__navbar-links_logo img {
    width: 50%;
  }
  .custom-logo-skeleton.MuiSkeleton-root {
    width: 50px;
    height: 50px;
  }
}
