form,
form input,
form textarea {
  padding: 1rem;
  font-family: var(--font-familyTwo);
}
.dark-theme form h2,
form button {
  color: #fdfdfd;
}
.dark-theme form button:hover img,
form button img {
  filter: brightness(0) invert(1);
}
.formApp {
  text-align: center;
  width: 520px;
  margin: 2rem;
}
form {
  display: flex;
  flex-direction: column;
  background: var(--color-secondary-background);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid var(--color-secondary-background);
  caret-color: var(--color-accent);
}
form input::placeholder,
textarea::placeholder {
  color: var(--color-primary);
  font-family: var(--font-familyTwo);
}
.dark-theme form input::placeholder,
.dark-theme textarea::placeholder {
  color: var(--color-primary);
}
form input:focus,
textarea:focus {
  outline: 0;
  border-color: var(--color-accent);
}
.dark-theme form input:hover,
.dark-theme textarea:hover,
form input:hover,
textarea:hover {
  border: 1.6px solid var(--color-accent);
}
form h2 {
  margin: 1.5rem 0;
  font-weight: 500;
  color: #212121;
}
.dark-theme form button:last-of-type,
form button:last-of-type,
form input,
form textarea {
  color: var(--color-primary);
}
form input,
form textarea {
  margin: 0.5rem 3rem;
  font-size: 1rem;
  border: 1.6px solid #f3f3f3;
  border-radius: 2rem;
  background-color: #fcfcfc;
}
.dark-theme form input,
.dark-theme form textarea {
  border: 1.6px solid var(--color-background);
  background-color: var(--color-background);
}
form textarea {
  resize: vertical;
}
form button {
  border-radius: 2rem;
  font-family: var(--font-family);
  padding: 0.7rem;
  font-weight: 500;
  background-color: var(--color-accent);
  border: 2px solid var(--color-accent);
  transition: background 0.2s;
  cursor: pointer;
  margin: 1rem 3rem 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
form button:first-of-type {
  margin: 1rem 3rem 0.5rem;
}
form button:last-of-type {
  background: 0 0;
  border: 2px solid transparent;
  margin: 1rem 3rem 0.5rem;
}
.dark-theme form button {
  color: #151515;
}
form button:hover {
  background: 0 0;
  color: var(--color-accent);
  border: 2px solid var(--color-accent);
}
form button:disabled {
  background: #e0e0e0;
  border: 2px solid #e0e0e0;
  cursor: default;
}
.dark-theme button:disabled {
  background: #1d1d1d;
  border: 2px solid #1d1d1d;
}
.dark-theme form button img,
form button:hover img {
  filter: brightness(1) invert(0);
}
form button img {
  width: 25px;
  height: 25px;
  margin: 0 0.5rem 0 0;
}
.error-msg {
  color: #ff474d;
  font-size: 0.8rem;
  text-align: left;
  margin: 0 4rem;
}
.dark-theme .error-msg {
  color: #c30101;
}
.error-outline {
  border: 1.6px solid #ff474d;
}
.dark-theme .error-outline {
  border: 1.6px solid #c30101;
}
@media screen and (max-width: 580px) {
  .error-msg {
    margin: 0 1.5rem;
  }
  .formApp,
  form input,
  form textarea {
    margin: 0.5rem;
  }
  .formApp {
    width: 400px;
  }
  form button img {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 450px) {
  .formApp,
  form input,
  form textarea {
    margin: 0.5rem;
  }
  .formApp {
    width: 340px;
  }
}
@media screen and (max-width: 375px) {
  .formApp {
    width: 290px;
    margin: 0.5rem;
  }
}
