.portfolio__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8rem 0;
}
.portfolio__header h1 {
  font-size: 2.6rem;
}
.hidden {
  display: none;
}
.btn-portfolio {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem 1rem;
  border-radius: 0.7rem;
  background: rgba(255, 255, 255);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: none;
  color: var(--color-text);
  font-family: var(--font-familyTwo);
  cursor: pointer;
  transition: filter 0.3s;
}
.active:hover,
.btn-portfolio:hover {
  filter: brightness(80%);
}
.dark-theme .btn-portfolio {
  background: #151515;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  color: #f5f5f5;
}
.active,
.dark-theme .active {
  background: var(--color-accent);
}
.portfolio__header {
  text-align: center;
}
.active {
  visibility: visible;
  color: #f5f5f5;
  transition: 0.3s;
}
.dark-theme .active {
  color: #151515;
}
@media screen and (min-width: 1024px) {
  .portfolio__header h1 {
    font-size: 3.5rem;
  }
  .portfolio-img {
    max-width: 300px;
  }
}
@media screen and (min-width: 746px) {
  .btn-portfolio {
    font-size: 1rem;
  }
}
