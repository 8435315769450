.dark-theme .ar__footer-content-links-set ul li img,
.dark-theme .ar__footer-content-logo img {
  filter: brightness(0) invert(1);
}
.ar__footer-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 0;
  font-family: var(--font-familyTwo);
}
.ar__footer-container {
  background: var(--color-secondary-background);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
.ar__footer-content-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ar__footer-content-logo img {
  width: 55px;
  margin: 1rem 0;
  filter: brightness(0%);
  transition: filter 0.3s;
  cursor: pointer;
}
.ar__footer-content-logo img:hover {
  filter: brightness(80%);
}
.ar__footer-content-links-set ul {
  list-style: none;
}
.ar__footer-content-links-set ul li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
  font-size: 0.8rem;
}
.ar__footer-content-links-set ul li a {
  transition: color 0.3s;
}
.ar__footer-content-links-set ul li a:hover {
  color: var(--color-accent);
}
.ar__footer-content-links-set ul li img {
  width: 25px;
  margin-right: 1rem;
}
.link-type {
  text-align: center;
  margin-top: 1.8rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.ar__footer-disclaimer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  text-align: center;
  padding: 0.5rem 0.5rem 1rem;
}
.ar__footer-content-logo p {
  text-align: center;
  max-width: 200px;
}
@media screen and (min-width: 768px) {
  .ar__footer-content-links-container {
    display: flex;
    flex-direction: row;
  }
  .ar__footer-content-links-set ul {
    margin: 0 6rem;
  }
}
@media screen and (min-width: 1024px) {
  .ar__footer-content {
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;
  }
  .ar__footer-content-links-set ul {
    margin: 0 6rem;
  }
  .ar__footer-content-links-set ul li {
    font-size: 1rem;
  }
  .ar__footer-disclaimer {
    font-size: 0.8rem;
  }
  .ar__footer-content-logo p {
    max-width: 150px;
  }
}
