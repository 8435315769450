.ar {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: var(--color-secondary-background);
  color: var(--color-primary);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid var(--color-secondary-background);
}

.ar h1,
.ar h2 .ar h3 {
  background: transparent;
  -webkit-text-fill-color: var(--color-primary);
}

.ar-feature {
  display: flex;
}

.skill-icon {
  font-size: 4rem;
  margin: 1.5rem;
}

.ar-feature h1 {
  margin-top: 1rem;
  font-size: 2rem;
  background: transparent;
  -webkit-text-fill-color: var(--color-primary);
}

.dark-theme .ar-feature h1 {
  -webkit-text-fill-color: var(--color-primary);
}
.ar-feature .gpt3__features-container__feature {
  margin: 0;
}

.ar-feature .gpt3__features-container_feature-text {
  max-width: 700px;
}

.ar-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 4rem 0 2rem;
}

.ar-heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
  background: transparent;
  -webkit-text-fill-color: var(--color-accent);
}

.ar-heading p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--color-highlight);
  cursor: pointer;
}

.ar-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  margin-top: 2rem;
}

.ar-container .gpt3__features-container__feature {
  flex: 1;
  margin: 1rem;
  min-width: 210px;
  display: unset;
  flex-direction: column;
}

.ar-container .gpt3__features-container_feature-text {
  margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
  .ar-heading {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0rem;
  }

  .ar-heading p {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .ar-feature .gpt3__features-container__feature {
    flex-direction: column;
  }

  .ar-feature .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
  }

  .ar-heading h1 {
    font-size: 28px;
    line-height: 40px;
  }
}

@media screen and (max-width: 350px) {
  .ar-container .gpt3__features-container__feature {
    margin: 1rem 0;
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .ar-heading h1 {
    margin-bottom: 2.5rem;
  }
}
