.descrip,
.highlight {
  font-family: var(--font-familyTwo);
  font-weight: 500;
  font-size: 1.2rem;
}
.ar__header {
  display: flex;
}
.ar__header-content {
  flex: 1.7;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;
}
.ar__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 3.4rem;
  line-height: 75px;
  letter-spacing: -0.04em;
}
.descrip {
  line-height: 30px;
  color: var(--color-accent);
  margin-top: 1.5rem;
  padding: 0 10rem 0 0;
  text-align: justify;
}
.highlight {
  color: var(--color-primary);
}
.ar__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.ar__header-content__input button {
  padding: 0.5rem 1rem;
  background: var(--color-accent);
  border-radius: 30px;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 15px;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
  color: #fff;
  border: 2px solid var(--color-accent);
  outline: 0;
  transition: background 0.2s;
}
.dark-theme .ar__header-content__input button {
  color: #212121;
}
.ar__header-content__input button:hover,
.dark-theme .ar__header-content__input button:hover {
  color: var(--color-accent);
  border: 2px solid var(--color-accent);
}
.ar__header-content__input button:hover {
  background: 0 0;
}
.ar__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ar__header-image img {
  width: 390px;
}
.hero-skeleton.MuiSkeleton-root {
  width: 390px;
  height: 405px;
}
.getStartedButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.getStartedButton img {
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
  filter: brightness(0) invert(1);
}
.dark-theme .getStartedButton img,
.getStartedButton:hover img {
  filter: brightness(1) invert(0);
}
.dark-theme .getStartedButton:hover img {
  filter: brightness(0) invert(1);
}
.header-socials-container img {
  width: 40px;
  height: 40px;
  margin: 0 1rem 1rem;
  cursor: pointer;
}
.dark-theme .header-socials-container img {
  filter: brightness(0) invert(1);
  transition: filter 0.2s;
}
.dark-theme .header-socials-container img:hover {
  filter: brightness(55%);
}
@media screen and (max-width: 1300px) {
  .descrip {
    padding: 0 2rem 0 0;
  }
}
@media screen and (max-width: 1024px) {
  .ar__header {
    display: flex;
    flex-direction: column;
  }
  .ar__header-content {
    margin: 0 0 3rem;
  }
  .ar__header-image img {
    width: 400px;
  }
  .hero-skeleton.MuiSkeleton-root {
    width: 400px;
    height: 415px;
  }
  .descrip {
    padding: 0;
  }
}
@media screen and (max-width: 900px) {
  .ar__header {
    display: flex;
    flex-direction: column;
  }
  .ar__header-content {
    margin: 0 0 3rem;
  }
  .ar__header-image img {
    width: 320px;
  }
  .hero-skeleton.MuiSkeleton-root {
    width: 320px;
    height: 335px;
  }
  .header-socials-container img {
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 650px) {
  .ar__header h1 {
    font-size: 48px;
    line-height: 60px;
  }
  .ar__header p {
    font-size: 16px;
    line-height: 24px;
  }
  .ar__header-content__input button {
    font-size: 14px;
    padding: 0.8rem 2rem;
  }
  .ar__header-content__input {
    justify-content: center;
  }
  .ar__header-image img {
    width: 300px;
  }
  .hero-skeleton.MuiSkeleton-root {
    width: 300px;
    height: 315px;
  }
  .descrip {
    padding: 0;
  }
  .getStartedButton img {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 490px) {
  .ar__header h1 {
    font-size: 36px;
    line-height: 48px;
  }
  .ar__header p {
    font-size: 14px;
    line-height: 24px;
  }
  .ar__header-content__input button {
    font-size: 12px;
    padding: 0.75rem 1.75rem;
  }
  .ar__header-image img {
    width: 320px;
  }
  .hero-skeleton.MuiSkeleton-root {
    width: 320px;
    height: 335px;
  }
}
