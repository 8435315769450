#contact,
.contact__content {
  flex-direction: column;
  display: flex;
}
#contact {
  margin: 8rem 0;
}
.section__heading {
  text-align: center;
}
.contact__content {
  justify-content: space-evenly;
  align-items: center;
}
.contact__left {
  display: flex;
  flex-direction: column;
}
.contact-illustration {
  width: 240px;
  margin: 2rem;
}
@media screen and (min-width: 500px) {
  .contact-illustration {
    width: 340px;
    margin: 2rem;
  }
}
@media screen and (min-width: 1024px) {
  .contact__content {
    flex-direction: row-reverse;
  }
  .contact-illustration {
    width: 450px;
  }
  .section__heading {
    font-size: 2.5rem;
  }
}
