.dark-theme,
.light-theme {
  background-color: var(--color-background);
  transition: background-color 0.3s;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: var(--font-family);
}
.light-theme {
  color: var(--color-primary);
}
.dark-theme {
  color: #edf2f7;
  --color-primary: #edf2f7;
  --color-background: #1a202c;
  --color-secondary-background: #12161f;
  --color-secondary: #a0aec0;
  --color-accent: #4fd1c5;
  --color-highlight: #ed8936;
}
a {
  color: unset;
  text-decoration: none;
}
.App {
  width: 100%;
}
.loader {
  margin: 17rem auto;
}
.gradient__text,
.gradient__text-secondary {
  -webkit-text-fill-color: transparent;
  margin: 0;
}
.gradient__text {
  background: var(--color-accent);
  background-clip: text;
  -webkit-background-clip: text;
}
.gradient__text-secondary {
  background: var(--color-secondary);
  background-clip: text;
  -webkit-background-clip: text;
}
.section__padding {
  padding: 4rem 6rem;
}
.section__margin {
  margin: 4rem 6rem;
}
.scale-up-center {
  -webkit-animation: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both scale-up-center;
  animation: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both scale-up-center;
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@media screen and (max-width: 900px) {
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    margin: 4rem;
  }
}
@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .section__margin {
    margin: 4rem 2rem;
  }
}
