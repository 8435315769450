@import url(https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=MuseoModerno:wght@100;200;300;400;500;600;700;800&display=swap);
:root {
  --font-family: 'MuseoModerno', cursive;
  --font-familyTwo: 'Jost', sans-serif;
  --color-background: #f7fafc;
  --color-secondary-background: #f7fafc;
  --color-primary: #2d3748;
  --color-secondary: #718096;
  --color-accent: #38b2ac;
  --color-highlight: #f6ad55;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: #2d3748;
}
::-webkit-scrollbar-thumb {
  background: #38b2ac;
}
::-webkit-scrollbar-thumb:hover {
  background: #4fd1c5;
}
::-webkit-scrollbar-button:single-button {
  background-color: #bbb;
  display: block;
  border-style: solid;
  height: 15px;
  width: 22px;
}
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 6px 6px;
  border-color: transparent transparent #2d3748;
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #4fd1c5;
}
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 6px 6px 0;
  border-color: #2d3748 transparent transparent;
}
::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #4fd1c5 transparent transparent;
}
